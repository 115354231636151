<template>
  <div class="c-promo-message" v-html="message"></div>
</template>

<script>

export default {
  name: 'CPromoMessage',
  components: { },
  props: {
    message: String
  }
}
</script>

<style lang="scss">
  .c-promo-message {
    margin-bottom: $vertical-margin;
  }
  .c-promo-message a {
    text-transform: none;
  }
</style>
